<template>
  <div
    :id="photo.file.id"
    :class="['card__wrapper', selected.length ? 'active' : '']"
  >
    <div class="image__container">
      <img
        class="image"
        :src="photo.file.fullPath"
      >
    </div>
    <b-button-close
      class="close-button"
      @click="$emit('deletePhoto', photo)"
    />
    <b-form-checkbox
      v-model="photo.is_preview"
      name="checkbox-1"
      class="preview_checkbox"
      @click.native="changeIsPreviewStatus(!photo.is_preview)"
    >
      Превью
    </b-form-checkbox>
    <div class="tags__wrapper">
      <div
        v-for="(tag, index) in photo.tags"
        :key="index"
        class="tag"
      >
        <span>
          {{ `#${tag.name}` }}
        </span>
        <b-button-close
          class="close"
          @click="deleteTag(tag)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { BButtonClose, BFormCheckbox } from 'bootstrap-vue'
import crudModule from '../../../libs/crud_module'

const photoTagsModule = crudModule({
  entity_name: 'albums-tags/photo',
})

const photoModule = crudModule({
  entity_name: 'album/photo',
})

export default {
  components: {
    BFormCheckbox,
    BButtonClose,
  },
  directives: {
    Ripple,
  },
  props: ['photo', 'hashtags', 'album'],
  data() {
    return {
      checkedPhoto: [],
      checkedPhotos: [],
      selected: [],
      modalShow: false,
    }
  },
  computed: {},
  // watch: {
  //   hashtags() {
  //     console.log(this.hashtags, this.selected)
  //     if (this.selected[0] && this.hashtags.length > 0) {
  //       this.hashtags.forEach(item => {
  //         if (this.photo.tags) {
  //           this.photo.tags.push(item)
  //           this.updateAlbumPhoto()
  //         } else {
  //           this.photo = { ...this.photo, tags: [] }
  //           this.photo.tags.push(item)
  //           this.updateAlbumPhoto()
  //         }
  //       })
  //     }
  //   },
  // },
  // created() {
  //   this.setChecked()
  // },
  methods: {
    // setChecked() {
    //   if (this.photo.tags && this.photo.tags.length > 0) {
    //     this.selected = [true]
    //     this.$emit('setSelectedPhoto', this.photo.file_id, this.selected)
    //   }
    //   return false
    // },
    async changeIsPreviewStatus(isPreviewStatus) {
      await photoModule
        .updateItem(this.photo.file.id, { ...this.photo, is_preview: isPreviewStatus })
        .then(response => {
          this.isloading = false
          return response
        })
        .catch(() => {
          this.isloading = false
        })
    },

    async deleteTag(tag) {
      await photoTagsModule
        .updateItem(this.photo.file.id, { name: tag.name })
        .then(response => {
          this.isloading = false
          return response
        })
        .catch(() => {
          this.isloading = false
        })
      this.photo.tags = this.photo.tags.filter(item => item.name !== tag.name)
    },
  },
}
</script>

<style lang="scss" scoped>
.card__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 180px;
  border: 1px solid #c3c2c7;
  padding: 15px 0 5px;
  border-radius: 5px;
  min-height: 230px;
  box-shadow: 0 2px 4px 0 #c3c2c7;
  cursor: pointer;

  .image__container {
    padding: 0 10px 5px;

    .image {
      width: 100%;
      height: 145px;
      border-radius: 5px;
    }
  }

  .close-button {
    position: absolute;
    top: -5px;
    right: 1px;
  }

  .tags__wrapper {
    align-self: center;
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 5px;
    padding: 0 10px;

    .tag {
      display: flex;
      align-items: center;
      // padding: 2px 5px;

      .close {
        margin-left: 3px;
      }
    }
  }

  .checkbox {
    position: absolute;
    bottom: 15px;
    left: 45%;
  }

  .preview_checkbox {
    align-self: flex-start;
    margin-left: 10px;
  }
}

.active {
  border-color: #5e50ee;
  box-shadow: 0 2px 4px #5e50ee;
}
</style>
